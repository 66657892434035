<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <!-- Filters -->
      <cashbook-list-filters
        :date-filter.sync="dateFilter"
        :date-filter-value.sync="dateFilterValue"
        :clear-date.sync="clearDate"
        :type-filter.sync="typeFilter"
        :type-options="typeOptions"
        :ledger-filter.sync="ledgerFilter"
        :ledger-options="ledgerOptions"
        :branch-filter.sync="branchFilter"
        :branch-options="branchOptions"
      />

      <!-- Table Container Card -->
      <b-card>
        <b-card-title>
          {{ $t('Cash Book') }}
          <span
            v-if="Math.sign(cashInOutBalance) === 1"
            class="float-right text-muted"
          >
            {{ $t('Balance') }} : <span class="text-dark">{{ cashInOutBalance.toLocaleString() }}</span>
          </span>
          <span
            v-if="Math.sign(cashInOutBalance) === -1"
            class="float-right text-muted"
          >
            {{ $t('Balance') }} : <span class="text-danger">{{ cashInOutBalance.toLocaleString() }}</span>
          </span>

        </b-card-title>

        <!-- body -->

        <div class="mb-2 mt-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start pl-0 mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')"
                  @keyup.enter="search"
                />
                <b-button
                  v-if="$can('create', 'cash_book')"
                  variant="primary"
                  pill
                  @click="add"
                >
                  <span class="text-nowrap">{{ $t('New') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-tabs>

          <b-tab active>
            <template #title>
              <feather-icon icon="ActivityIcon" />
              <span>{{ $t('Detail') }}</span>
            </template>

            <b-card no-body>
              <b-card-title class="pt-2 pl-1">
                {{ $t('Transactions') }}
              </b-card-title>

              <b-table
                ref="refCashbookListTable"
                class="position-relative"
                :items="fetchCashbooks"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                stacked="sm"
              >

                <!-- Column: Ledger -->
                <template #cell(ledger)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ data.item.ledger.name }}</span>
                  </div>
                </template>

                <!-- Column: Type -->
                <template #cell(type)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ data.item.type.split('_')[1] }}</span>
                  </div>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-link
                    v-if="$can('update', 'cash_book')"
                    :to="{ name: 'cashbook-edit', params: { id: data.item.id } }"
                    class="btn btn-light btn-icon rounded-pill mr-1"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                    />
                  </b-link>
                  <b-link
                    v-if="$can('delete', 'cash_book')"
                    class="btn btn-light btn-icon rounded-pill"
                    @click="del(data.item.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="text-danger"
                    />
                  </b-link>

                </template>

              </b-table>

            </b-card>

          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="BookOpenIcon" />
              <span>{{ $t('Summary') }}</span>
            </template>
            <b-row>

              <b-col
                md="6"
                sm="12"
                xs="12"
              >
                <b-card no-body>
                  <b-card-title class="pt-2 pl-1 pr-1">
                    {{ $t('Cash In') }} <span class="float-right">{{ $t('Total Amount') }} : {{ cashInTotal.toLocaleString() }}</span>
                  </b-card-title>

                  <b-table
                    class="position-relative"
                    :items="cashIn"
                    responsive
                    :fields="cashInOutColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                  />

                </b-card>
              </b-col>

              <b-col
                md="6"
                sm="12"
                xs="12"
              >
                <b-card no-body>
                  <b-card-title class="pt-2 pl-1 pr-1">
                    {{ $t('Cash Out') }} <span class="float-right">{{ $t('Total Amount') }} : {{ cashOutTotal.toLocaleString() }}</span>
                  </b-card-title>

                  <b-table
                    class="position-relative"
                    :items="cashOut"
                    responsive
                    :fields="cashInOutColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                  />

                </b-card>
              </b-col>

            </b-row>
          </b-tab>

        </b-tabs>

        <!-- Pagination -->
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalCashbooks"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>

      </b-card>

    </div>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable,
  BPagination, BTabs, BTab, BCardTitle, BLink,
} from 'bootstrap-vue'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import CashbookListFilters from './CashbookListFilters.vue'
import storeModule from '../../../common/storeModule'
import useCashbookList from './useCashbookList'
import cashbookStoreModule from '../cashbookStoreModule'

export default {
  components: {
    CashbookListFilters,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTabs,
    BTab,
    BCardTitle,
    BLink,
    vSelect,
  },
  data() {
    return {
      ledgerOptions: [],
      branchOptions: [],
      tableColumns: [
        { key: 'cash_date', label: this.$t('Date'), sortable: true },
        { key: 'description', label: this.$t('Description'), sortable: true },
        { key: 'amount', label: this.$t('Amount'), sortable: true },
        { key: 'type', label: this.$t('Type'), sortable: true },
        { key: 'ledger', label: this.$t('Category'), sortable: true },
        { key: 'actions', label: this.$t('Actions') },
      ],
      cashInOutColumns: [
        { key: 'cash_date', label: this.$t('Date'), sortable: false },
        { key: 'description', label: this.$t('Description'), sortable: false },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
      ],
      typeOptions: [
        { label: 'Cash In', value: 'cash_in' },
        { label: 'Cash Out', value: 'cash_out' },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    branchFilter() {
      this.loadLedgers()
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'cashbook'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cashbookStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const clearDate = null

    const {
      fetchCashbooks,
      cashIn,
      cashOut,
      cashInTotal,
      cashOutTotal,
      cashInOutBalance,
      perPage,
      currentPage,
      totalCashbooks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCashbookListTable,
      refetchData,

      // Filters
      typeFilter,
      ledgerFilter,
      branchFilter,
      dateFilter,
      dateFilterValue,

    } = useCashbookList()

    perPageOptions.push(totalCashbooks)

    return {

      fetchCashbooks,
      cashIn,
      cashOut,
      cashInTotal,
      cashOutTotal,
      cashInOutBalance,
      perPage,
      currentPage,
      totalCashbooks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCashbookListTable,
      refetchData,

      clearDate,
      // Extra Filters
      typeFilter,
      ledgerFilter,
      branchFilter,
      dateFilter,
      dateFilterValue,

    }
  },
  mounted() {
    this.loadLedgers()
    this.loadBranches()
  },
  methods: {
    loadLedgers() {
      this.ledgerOptions = []
      this.ledgerFilter = null
      store
        .dispatch('common/fetchLedgers', { id_name: 1, branch_id: this.branchFilter })
        .then(response => {
          const { ledgers } = response.data.data
          for (let i = 0; i < ledgers.length; i += 1) {
            this.ledgerOptions.push({ label: ledgers[i].name, value: ledgers[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.refetchData()
    },
    add() {
      // add cashbook codes
      this.$router.push({ name: 'cashbook-new' })
    },
    del(id) {
      const that = this
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('cashbook/del', id)
            .then(response => {
              if (response.status === 200) {
                that.refetchData()
              } else {
                console.log(response)
              }
            })
            .catch(error => {
              window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 100px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
